import React from 'react'
import Layout from '../utils/Layout'
import { FiFacebook } from 'react-icons/fi';
import { FaViber, FaTelegram } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <Layout title="Contact Us" smTitle="Contact">
         <div className='flex items-center justify-between pb-10 flex-wrap'>
              <div className='m-3 w-full lg:w-auto lg:mx-14 group'>
                  <div className='flex items-center'>
                       <div className='w-12 h-12 rounded-full bg-neutral-200 group-hover:bg-[#c865fa] transition duration-700 ease-in-out flex items-center justify-center'>
                          <FiFacebook className='text-[#010320] text-xl lg:text-2xl group-hover:text-[#FFF] transition duration-700 ease-in-out' />
                       </div>
                       <div className='pl-5'>
                           <h4 className='text-neutral-200 text-base font-extrabold'>Facebook :</h4>
                           <p className='mb-0 text-neutral-200 text-sm font-extrabold'>www.facebook.com</p>
                       </div>
                  </div>
              </div>
              <div className='m-3 w-full lg:w-auto lg:mx-14 group'>
                    <Link to="viber://chat?number=09887766557" target='_blank'>
                        <div className='flex items-center'>
                            <div className='w-12 h-12 rounded-full bg-neutral-200 group-hover:bg-[#c865fa] transition duration-700 ease-in-out flex items-center justify-center'>
                                <FaViber className='text-[#010320] text-xl lg:text-2xl group-hover:text-[#FFF] transition duration-700 ease-in-out' />
                            </div>
                            <div className='pl-5'>
                                <h4 className='text-neutral-200 text-base font-extrabold'>Viber :</h4>
                                <p className='mb-0 text-neutral-200 text-sm font-extrabold'>09 887 766 557</p>
                            </div>
                        </div>
                    </Link>
              </div>
              <div className='m-3 w-full lg:w-auto lg:mx-14 group'>
                    <Link to="https://t.me/+959887766557" target='_blank'>
                        <div className='flex items-center'>
                            <div className='w-12 h-12 rounded-full bg-neutral-200 group-hover:bg-[#c865fa] transition duration-700 ease-in-out flex items-center justify-center'>
                                <FaTelegram className='text-[#010320] text-xl lg:text-2xl group-hover:text-[#FFF] transition duration-700 ease-in-out' />
                            </div>
                            <div className='pl-5'>
                                <h4 className='text-neutral-200 text-base font-extrabold'>Telegram :</h4>
                                <p className='mb-0 text-neutral-200 text-sm font-extrabold'>09 887 766 557</p>
                            </div>
                        </div>
                    </Link>
              </div>
         </div>
    </Layout>
  )
}

export default Contact
