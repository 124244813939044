import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {BiSolidCopy} from 'react-icons/bi';
import Input from '../utils/Input';
import Button from '../utils/Button';
import classes from '../assets/styles/DepositForm.module.css';
import RadioInput from '../utils/RadioInput';
import { useDispatch, useSelector } from 'react-redux';
import {depositHandler} from '../store/slices/depositSlice';
import {getMethod, postMethod, postMethodFormData} from '../services';
import { PAYMENT_PROVIDER_API, USER_DEPOSIT_API, USER_INFO_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import Loader from '../utils/Loader';
import PaymentList from '../utils/PaymentList';
import { copyToClipboard } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { AiFillDollarCircle} from 'react-icons/ai';
import { FiFileText, FiImage, FiUnlock } from 'react-icons/fi';
import config from '../config';

const DepositForm = () => {
  const [loading,setLoading] = useState(true);
  const [payments,setPayments] = useState([]);
  const [userId,setUserId] = useState(null);
  const [userBalance,setUserBalance] = useState(null);
  const [amount,setAmount] = useState("");
  const [confirmState,setConfirmState] = useState(false);
  const [password,setPassword] = useState("");
  const [transno,setTransno] = useState("");

  const [errorAmount,setErrorAmount] = useState("");
  const [errorPassword,setErrorPassowrd] = useState("");
  const [errorTransno,setErrorTransno] = useState("");

  const {value} = useSelector(state => state.depositStoreSlice);
  const dispatch = useDispatch();
  const [choosePayment, setChoosePayment] = useState(null);
  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    const token = localStorage.getItem('l_k_auth');
    if(token){
        const deToken = JSON.parse(token);
        let res = await getMethod(USER_INFO_API, deToken.token);
        if(res){
            if(res.con){
                setUserId(res?.data?.user_info?.id);
                setUserBalance(res?.data?.user_info?.balance);
            };
        };
    };
  };

  const fetchPaymentProviders = async () => {
      const token = localStorage.getItem('l_k_auth');
      if(token){
         const deToken = JSON.parse(token);
         const res = await getMethod(`${PAYMENT_PROVIDER_API}?app_name_id=${config.app_name_id}`, deToken.token);
         if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
                setLoading(false);
                SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: 'Please check internet connection!',
                  confirmButtonText: 'Ok',
                });
            };
            if(res.con){
                setPayments(res.data);
            };
         };
      };
  };
 
  useEffect(()=>{
     fetchPaymentProviders();
     fetchUserInfo();
  },[]);

  const amountHandler = (e) => {
      setAmount(e.target.value);
      setErrorAmount("");
  };
  const customAmountHandler = e => {
    setErrorAmount("");
    setAmount(e.target.value);
    let isChecked = document.querySelectorAll('.isChecked');
    isChecked.forEach(input => {
        return input.checked = false;
    });
  };

  useEffect(()=>{
    const data = {
        user_id: userId,
        payment_provider_id: choosePayment?.id,
        amount,
    };
  dispatch(depositHandler(data))
  },[amount]);

  const nextHandler = () => {
     setErrorAmount("");
     if(value.payment_provider_id){
        if(value.amount){
             if(value.amount >= 5000){
                setConfirmState(true);
             }else{
                setErrorAmount("Minium deposit amount is must be 5000!");
             }
        }else{
            setErrorAmount("Amount field is required!");
        };
     }else{
        setErrorAmount("Please choose payment type!");
     };
  };

  const depositSubmitHandler = async (e) => {
      e.preventDefault();
          if(transno){
             if(transno.length === 6){
                setLoading(true);
                const token = localStorage.getItem('l_k_auth');
                if(token){
                    const deToken = JSON.parse(token);
                    const data = {
                        user_id: userId,
                        payment_provider_id: value?.payment_provider_id,
                        amount: value?.amount,
                        transaction_no: transno,
                        phone: choosePayment.phone,
                        password
                    };
                    const res = await postMethod(USER_DEPOSIT_API, data, deToken.token)
                    if(res){
                        setLoading(false);
                        if(res === 'Failed to fetch'){
                            SweetAlert.fire({
                              width: '330px',
                              icon: 'error',
                              title: 'Connection!',
                              text: 'Please check internet connection!',
                              confirmButtonText: 'Ok',
                            });
                        };
                        if(res.con){
                            SweetAlert.fire({
                                width: '330px',
                                icon: 'success',
                                title: 'Success!',
                                text: 'Deposit successful!',
                                confirmButtonText: 'Ok',
                            }).then(result => {
                                if(result.isConfirmed){
                                    navigate('/');
                                }
                            });
                        }else{
                            if(res.message !== null){
                                if(res.message.transaction_no){
                                    setErrorTransno(res.message.transaction_no[0])
                                };
                                if(res.data.error){
                                    setErrorPassowrd(res.data.error);
                                };
                            }
                        }
                    }
                };
             }else{
                setErrorTransno("Transaction no is must be 6 digits!")
             };
          }else{
            setErrorTransno("Transaction no is required!")
          }
  };

  const paymentSelectHandler = (payment) => {
     setChoosePayment(payment);
  };
  return (
    <>
        <div className={classes['auth-card-body']}>
                {
                    confirmState? 
                    <>
                        <div className={classes['payment-select']}>
                            <img src={choosePayment?.photo} />
                            <div className='payment-owner-phone'>
                                <p>{choosePayment?.phone}</p>
                                <p>{choosePayment?.owner}</p>
                            </div>
                            <span>
                                <BiSolidCopy onClick={e => copyToClipboard(choosePayment?.phone)}/>
                            </span>
                        </div>
                        <p className={classes['note']}>မှတ်ချက် : <span>ဖော်ပြပါ အကောင့်ကိုသာငွေလွဲပါ</span></p>
                        <hr style={{marginBottom: '30px',borderColor: '#2e3058'}} />
                        <div className={classes['confirm-container']}>
                            <p>
                                Amount  &nbsp;: <span>{Number(value?.amount)?.toLocaleString('en-US')}</span>
                            </p>
                            <form onSubmit={depositSubmitHandler}>
                                <Input label="Transaction no" icon={<FiFileText/>} type="number" value={transno} placeholder="Last 6 transaction No" event={e => {
                                        if(e.target.value.length > 6){
                                            return;
                                        }
                                        setErrorTransno("");
                                        setTransno(e.target.value)
                                    }
                                } error={errorTransno}/>
                                <Input label="Password" icon={<FiUnlock/>} type="password" value={password} event={e => {
                                        if(e.target.value.length > 8){
                                            return;
                                        }
                                        setErrorPassowrd("");
                                        setPassword(e.target.value)
                                    }
                                } error={errorPassword}/>
                                <div className={classes['btn-position']}>
                                    <Button label="Confirm" type="submit" bgColor="#6b67eb" bgHover="#333258" loading={loading} disable={loading}/>
                                </div>
                            </form>
                        </div>
                    </>
                    :
                    <>
                        <ul className={classes['payment-flex']}>
                            {
                                payments.length > 0 &&
                                payments.map((payment,i) => 
                                    <PaymentList key={i} data={choosePayment} id={payment.id} photo={payment.photo} event={e => paymentSelectHandler(payment)} />
                                )
                            }
                        </ul>
                        {choosePayment &&
                        <form>
                            <hr style={{marginBottom: '30px',borderColor: '#2e3058'}} />
                            <Input type="number" icon={<AiFillDollarCircle/>} label="Amount" value={amount} event={customAmountHandler} error={errorAmount}/>
                            <div className={classes['amount-container']}>
                                <RadioInput id="1" value="5000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="2" value="10000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="3" value="20000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="4" value="30000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="5" value="50000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="6" value="100000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="7" value="200000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="8" value="300000" amountEvent={amountHandler} checked={true}/>
                            </div>
                            <div className={classes['submit-position']}>
                                <Button label="Next" type="button" bgColor="#6b67eb" bgHover="#333258" event={nextHandler}/>
                            </div>
                        </form>}
                    </>
                }
                
        </div>
        {
            ReactDOM.createPortal(loading && <Loader/>, document.getElementById("portal"))
        }
    </>
  )
}

export default DepositForm