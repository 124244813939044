import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../layout/Header';
import Card from '../utils/Card';
import Footer from '../layout/Footer';
import classes from '../assets/styles/Deposit.module.css';
import WithdrawForm from './WithdrawForm';
import WithdrawHistory from './WithdrawHistory';

const Withdraw = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  return (
    <>
      <Header />
      <div className='mx-auto bg-[#010320] h-screen pt-16'>
        <Card>
            <div className={classes['auth-card-header']}>
                 <span className={`${url === '/withdraw' && classes.active}`} onClick={()=> navigate('/withdraw')}>Withdraw</span>
                 <span className={`${url === '/withdraw-history' && classes.active}`} onClick={()=> navigate('/withdraw-history')}>History</span>
            </div>
            {
              url === '/withdraw'?
              <WithdrawForm /> :
              <WithdrawHistory />
            } 
        </Card>
      </div>
      <Footer />
    </>
  )
}

export default Withdraw
