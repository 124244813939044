import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Input from '../utils/Input';
import Card from '../utils/Card';
import Button from '../utils/Button';
import { FiLock, FiPhone } from 'react-icons/fi';
import logo from '../assets/images/logo/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import classes from '../assets/styles/Login.module.css'
import { postMethod } from '../services';
import { LOGIN_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config';
import Loader from '../utils/Loader';

const Login = () => {
    const [loading,setLoading] = useState(false);
    const [phone,setPhone] = useState("");
    const [password,setPassword] = useState("");
    const [errorPhone,setErrorPhone] = useState("");
    const [errorPassword,setErrorPassword] = useState("");
    const navigate = useNavigate();

    const phoneHandler = e => {
        setPhone(e.target.value);
        setErrorPhone("");
    };
    const passwordHandler = e => {
        if(e.target.value.length > 8){
            return;
        }
        setPassword(e.target.value);
        setErrorPassword("");
    };

    const loginHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
          phone,password,
          app_name_id: config.app_name_id,
        };
        const res = await postMethod(LOGIN_API, data);
        if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
              SweetAlert.fire({
                width: '330px',
                icon: 'error',
                title: 'Connection!',
                text: 'Please check internet connection!',
                confirmButtonText: 'Ok',
              });
            }
            if(res.con){
              SweetAlert.fire({
                width: '330px',
                icon: 'success',
                title: 'Success!',
                text: 'Login successful!',
                confirmButtonText: 'Welcome',
              }).then(result => {
                  if(result.isConfirmed){
                      localStorage.setItem('l_k_auth', JSON.stringify(res.data));
                      navigate('/');
                  };
              });
            }else{
              if(res.data.name){
                setErrorPhone(res.data.name[0]);
              };
              if(res.data.password){
                  setErrorPassword(res.data.password[0]);
              };
              if(res.data.error){
                setErrorPhone(res.data.error);
              };
            };
        };
    };

  return (
    <>
      <div className='mx-auto bg-[#010320] h-screen pt-16'>
          <div>
              <div className='w-40 mb-6 mx-auto'>
                  <Link to="/">
                      <img src={logo} className='w-full'/>
                  </Link>
              </div>
          </div>
          <Card>
              <form onSubmit={loginHandler}>
                  <Input icon={<FiPhone />} label="Phone" type="number" value={phone} event={phoneHandler} error={errorPhone}/>
                  <Input icon={<FiLock />} label="Password" type="password" value={password} event={passwordHandler} error={errorPassword}/>
                  <div className='text-center'>
                      <Button label="Login" type="submit" disable={loading}/>
                  </div>
              </form>
          </Card>
          <div className={classes['forget-pass']}>
              <Link to="/forgot-password">
                  <p className={classes['forget-text']}>Forgot your password?</p>
              </Link>
              <hr className={classes['line-through']} />
              <Link to="/register">
                  <p className={classes['forget-text']}>Create account</p>
              </Link>
          </div>
      </div>
      {
         ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default Login
