import React from 'react';
import { useNavigate } from 'react-router-dom';
import DepositForm from './DepositForm';
import DepositHistory from './DepositHistory';
import Header from '../layout/Header';
import Card from '../utils/Card';
import Footer from '../layout/Footer';
import classes from '../assets/styles/Deposit.module.css';

const Deposit = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  return (
    <>
      <Header />
      <div className='mx-auto bg-[#010320] h-screen pt-16'>
        <Card>
            <div className={classes['auth-card-header']}>
                 <span className={`${url === '/deposit' && classes.active}`} onClick={()=> navigate('/deposit')}>Deposit</span>
                 <span className={`${url === '/deposit-history' && classes.active}`} onClick={()=> navigate('/deposit-history')}>History</span>
            </div>
            {
              url === '/deposit'?
              <DepositForm /> :
              <DepositHistory />
            } 
        </Card>
      </div>
      <Footer />
    </>
  )
}

export default Deposit
