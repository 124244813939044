import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import  '../assets/styles/Banner.css';
import config from '../config';
import {BANNER_API} from '../services/constant';
import {getMethod} from '../services';
import Caption from './Caption';

const Banner = () => {
    const [banners,setBanners] = useState([]);
    const fetchBanner = async () => {
        const res = await getMethod(`${BANNER_API}?app_name_id=${config.app_name_id}`);
        if(res.con){
            setBanners(res.data);
        }
    };
    useEffect(()=>{
        fetchBanner();
    },[]);
  return (
    <>
    <div className='relative'>
        <div className='absolute top-0 z-10 w-full'>
            <Caption />
        </div>
        <Swiper
            spaceBetween={10}
            pagination={{
            clickable: true,
            }}
            modules={[Pagination,Autoplay]}
            className="mySwiper"
            autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            }}
            loop={true}
            speed={3000}
        >
        {
            banners.length > 0 &&
            banners.map((banner,i) => 
            <SwiperSlide key={i}>
                <img className={'banner-img'} src={banner.photo} />
            </SwiperSlide>
            )
        }
        </Swiper>
    </div>
    </>
  )
}

export default Banner