import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from '../assets/styles/ProviderList.module.css';
import Layout from '../utils/Layout';
import { Link, useAsyncError, useNavigate } from 'react-router-dom';
import ProviderCard from '../utils/ProviderCard';
import { getMethod } from '../services';
import {PROVIDER_CONNECT_API,PROVIDER_API} from '../services/constant';
import SweetAlert from 'sweetalert2';
import all from '../assets/images/all.png';
import Loader from '../utils/Loader';

const ProviderList = () => {
    const [loading,setLoading] = useState(true);
    const [providerConnectLists,setProviderConnectLists] = useState([]);
    const [providers,setProviders] = useState([]);
    const [filterProviders,setFilterProviders] = useState([]);
    const [allActive,setAllActive] = useState(null);
    const [active,setActive] = useState(null);
    const navigate = useNavigate();
    const [imageLoad,setImageLoad] = useState(true);

    const fetchProviderConnect = async () => {
        const res = await getMethod(`${PROVIDER_CONNECT_API}?status=active&website=true`);
        if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
               SweetAlert.fire({
                 width: '330px',
                 icon: 'error',
                 title: 'Connection!',
                 text: 'Please check internet connection!',
                 confirmButtonText: 'Ok',
               });
           }
           if(res.con){
               setProviderConnectLists(res.data);
               setAllActive('all');
               setActive(null);
           }
        };
   };

   const fetchProvider = async () => {
       const res = await getMethod(`${PROVIDER_API}?status=active&website=true`);
       if(res){
           setLoading(false);
           if(res.con){
               setProviders(res.data);
               setAllActive('all');
               setActive(null);
               setFilterProviders([]);
           }
       };
   };
   useEffect(() => {
       fetchProviderConnect();
       fetchProvider();
   },[]);

    const providerConnectHandler = (list) => {
        setAllActive(null);
        setActive(list.name);
        setProviders([]);
        setFilterProviders(list.types);
    };
    const allProviderConnectHandler = () => {
        setLoading(true);
        fetchProvider();
    };

    const gamesListHandler = provider => {
        if(provider){
            navigate(`/${provider.type?provider.type.toLowerCase(): "all"}/${provider.provider_id?provider.provider_id:provider.id}`);
        };
    };

  return (
    <>
        <Layout smTitle="Games" title="Providers">
            <div className='mb-5'>
                <ul className='flex items-center justify-start overflow-auto w-full'>
                    <li className={`mx-2 cursor-pointer`} onClick={allProviderConnectHandler}>
                        <div className={`flex items-center w-32 lg:w-52 py-3 px-4 rounded bg-${allActive === 'all'?'[#c865fa8f]':'[#1e2b3e]'}`}>
                            <img className='w-7 lg:w-12' src={all} alt='provider' />
                            <p className={`text-neutral-200 text-base lg:text-xl ml-2 lg:ml-8`}>All</p>
                        </div>
                    </li>
                    {
                        providerConnectLists.length > 0 &&
                        providerConnectLists.map((list,i) =>
                            <li key={i} className={`mx-2 cursor-pointer`} onClick={() => providerConnectHandler(list)}>
                                <div className={`flex items-center w-32 lg:w-52 py-3 px-4 rounded bg-${active === list.name ?'[#c865fa8f]':'[#1e2b3e]'}`}>
                                    <img className='w-7 lg:w-12' src={`https://docs-chail.sgp1.digitaloceanspaces.com/${list.photo}`} />
                                    <p className={`text-neutral-200 text-base lg:text-xl ml-2 lg:ml-8`}>{list.name}</p>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className='rounded-2xl'>
                <ul className='flex items-center justify-center flex-wrap w-full'> 
                    {
                        allActive === "all"?
                        providers.length > 0 &&
                        providers.map((provider,i) => 
                            <ProviderCard key={i} name={provider?.full_name} image={provider?.photo} imageLoad={imageLoad} setImageLoad={setImageLoad} event={e => gamesListHandler(provider)}/>
                        )
                        :
                        filterProviders.length > 0 && 
                        filterProviders.map((provider,i) => 
                            <ProviderCard key={i}  name={provider?.provider?.full_name} image={provider?.provider?.photo} imageLoad={imageLoad} setImageLoad={setImageLoad} event={e => gamesListHandler(provider)}/>
                        )
                    }
                </ul>
            </div>
        </Layout>
        {
           ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
        }
    </>
  )
};

export default ProviderList