import React from 'react'

const Footer = () => {
  return (
    <div className='p-7 bg-[#1e2b3e]'>
        <div className='container mx-auto'>
            <p className='mb-0 text-center'>&copy; Copyright. All Rights Reserved</p>
        </div>
    </div>
  )
}

export default Footer;
