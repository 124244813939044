import SweetAlert from 'sweetalert2';

export async function copyToClipboard(copyMe) {
  await navigator.clipboard.writeText(copyMe);
  SweetAlert.fire({
      icon: 'success',
      width: 300,
      title: 'Success',
      text: "Copy to Clipboard",
  })
};