const domain = process.env.REACT_APP_DOMAIN;
// const domain = 'http://192.168.0.18:8080/';

export const REGISTER_API = `${domain}api/register`;
export const REGISTER_OTP_API = `${domain}api/getOTP`;
export const PASSWORD_RESET_API = `${domain}api/password/reset`;
export const PASSWORD_CREATE_OTP_API = `${domain}api/password/create`;
export const LOGIN_API = `${domain}api/login`;
export const CHANGE_PASSWORD_API = `${domain}api/change-password`;
export const USER_INFO_API = `${domain}api/user_info`;
export const PROVIDER_CONNECT_API = `${domain}api/provider-connect-lists`;
export const PROVIDER_API = `${domain}api/providers`;
export const GAMES_LIST_API = `${domain}api/game-lists`;
export const GAME_LAUNCH_API = `${domain}api/slot/init`;
export const PAYMENT_PROVIDER_API = `${domain}api/payment-providers`;

export const USER_DEPOSIT_API = `${domain}api/slot/deposits`;
export const DEPOSIT_HISTORY_API = `${domain}api/slot/deposits`;
export const USER_WITHDRAW_API = `${domain}api/slot/withdraws`;
export const WITHDRAW_HISTORY_API = `${domain}api/slot/withdraws`;
export const BANNER_API = `${domain}api/banners`;
export const CAPTION_API = `${domain}api/caption`;