import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '../utils/Button';
import { Link, useNavigate } from 'react-router-dom';
import {postMethod} from '../services';
import Input from '../utils/Input';
import logo from '../assets/images/logo/logo.png';
import { CHANGE_PASSWORD_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import Card from '../utils/Card';
import { FiLock } from 'react-icons/fi';
import Loader from '../utils/Loader';

const ChangePassword = () => {
  const [loading,setLoading] = useState(false);
  const [oldPassword,setOldPassword] = useState("");
  const [newPassword,setNewPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState(""); 

  const [errorOldPassword,setErrorOldPassword] = useState("");
  const [errorNewPassword,setErrorNewPassword] = useState("");
  const [errorConfirmPassword,setErrorConfirmPassword] = useState("");
  const navigate = useNavigate();

  const oldPasswordHandler = e => {
    if(e.target.value.length > 8){
      return;
    }
    setOldPassword(e.target.value);
    setErrorOldPassword("");
  };

  const newPasswordHandler = e => {
    if(e.target.value.length > 8){
      return;
    };
     setNewPassword(e.target.value);
     setErrorNewPassword("");
  };

  const confirmPasswordHandler = e => {
    if(e.target.value.length > 8){
      return;
    }
     setConfirmPassword(e.target.value);
     setErrorConfirmPassword("");
  }

  const changePasswordHandler = async e => {
     e.preventDefault();
     setLoading(true);
     const token = localStorage.getItem('l_k_auth');
     const data = {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: confirmPassword
     };
     if(token){
          const deToken = JSON.parse(token);
          const res = await postMethod(CHANGE_PASSWORD_API, data, deToken.token);
          if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
              SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: 'Please check internet connection!',
                  confirmButtonText: 'Ok',
                });
            }
            if(res.con){
                SweetAlert.fire({
                  width: '330px',
                  icon: 'success',
                  title: 'Success!',
                  text: 'Password change successful!',
                  confirmButtonText: 'Ok',
                }).then(result => {
                    if(result.isConfirmed){
                        localStorage.removeItem('l_k_auth');
                        navigate('/login');
                    };
                });
            };
          }
     }else{
        navigate('/login');
     }
     
  }
  return (
    <>
      <div className='mx-auto bg-[#010320] py-16 h-screen'>
            <div>
                <div className='w-40 mb-6 mx-auto'>
                    <Link to="/">
                        <img src={logo} className='w-full'/>
                    </Link>
                </div>
                <Card>
                    <form onSubmit={changePasswordHandler}>
                        <Input icon={<FiLock />} label="Old password" type="password" value={oldPassword} event={oldPasswordHandler} error={errorOldPassword}/>
                        <Input icon={<FiLock />} label="New password" type="password" value={newPassword} event={newPasswordHandler} error={errorNewPassword}/>
                        <Input icon={<FiLock />} label="Confirm password" type="password" value={confirmPassword} event={confirmPasswordHandler} error={errorConfirmPassword}/>
                        <div className='text-center'>
                            <Button label="Confirm" type="submit" disable={loading}/>
                        </div>
                    </form>
                </Card>
            </div>
      </div>
      {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default ChangePassword
