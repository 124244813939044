import React from 'react';
import classes from '../assets/styles/NoData.module.css';

const NoData = () => {
  return (
    <div className={classes['nofount']}>
         No data found!
    </div>
  )
}

export default NoData