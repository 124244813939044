import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import Layout from '../utils/Layout'
import { useParams } from 'react-router-dom'
import {PROVIDER_API,GAMES_LIST_API,GAME_LAUNCH_API} from '../services/constant';
import {getMethod,postMethod} from '../services';
import SweetAlert from 'sweetalert2';
import Pagination from '../utils/Pagination';
import classes from '../assets/styles/GameList.module.css';
import ProviderCard from '../utils/ProviderCard';
import Loader from '../utils/Loader';

const GameList = () => {
    const [loading,setLoading] = useState(true);
    const [provider,setProvider] = useState({});
    const [games,setGames] = useState([]);
    const [filterGames, setFilterGames] = useState([]);
    const [searchGames,setSearchGames] = useState([]);
    const [page,setPage] = useState(1);
    const [totalPage,setTotalPage] = useState(1);
    const [from,setFrom] = useState(1);
    const params = useParams();
    const [imageLoad,setImageLoad] = useState(true);


    const fetchProvider = async () => {
        const res = await getMethod(`${PROVIDER_API}/${params.id}`);
        if(res){
          setLoading(false);
           if(res === 'Failed to fetch'){
               SweetAlert.fire({
                 width: '330px',
                 icon: 'error',
                 title: 'Connection!',
                 text: 'Please check internet connection!',
                 confirmButtonText: 'Ok',
               });
           };
           if(res.con){
             setProvider(res.data);
           }
        };
     };
     useEffect(()=> {
       fetchProvider();
     },[]);

     const fetchGames = async () => {
        if(provider.name){
            setLoading(true)
            let res = await getMethod(`${GAMES_LIST_API}?system_status=active&website=true&g_type=${params.name}&p_code=${provider?.name}&sortColumn=g_type&page=${page}&limit=500`);
            if(res){
              setLoading(false);
               if(res.con){
                  setGames(res.data);
                  setFilterGames([]);
                  setSearchGames("");
                  setFrom(res?.meta?.from)
                  setTotalPage(res?.meta?.last_page);
               };
            };
        };
    };
  
    useEffect(()=> {
        fetchGames();
    },[provider,page]);

    const searchGameHandler = (e) => {
        let str = e.target.value;
        setSearchGames(e.target.value);
        let arr = str.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        let str2 = arr.join(" ");
        let temp = [];
        if(games.length > 0){
            games.map((el) => {
                if(el.gameName.startsWith(str2)){
                    temp.push(el);
                }
            })
        };
        setFilterGames(temp);
    };

    const launch = async (name,type,code,newPara) => {
        setLoading(true);
        const token = localStorage.getItem('l_k_auth');
        if(token){
            const deToken = JSON.parse(token);
            const data = {
                provider_name: name,
                p_code: name,
                p_type: type,
                g_code: code,
                h5: newPara
            };
            const res = await postMethod(GAME_LAUNCH_API, data, deToken.token);
            if(res){
              if(res === 'Failed to fetch'){
                SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: 'Please check internet connection!',
                  confirmButtonText: 'Ok',
                });
              };
              setLoading(false);
                if(res.con){
                  if(res.data.gameUrl){
                      if(!window.open(res.data.gameUrl)) {
                          window.location.href = res.data.gameUrl;
                      };
                  };
                }
            }
    
        }
    
    };
    
    const launchGame = (name,type,code,h5) => {
        let newPara = h5 === "mobile"? 1: 0;
        launch(name,type,code,newPara);
    };
  return (
    <>
        <div>
        <Header />
            <Layout title={provider?.full_name} smTitle="Game">
                <div className='min-h-screen py-10'>
                    <div className='flex-col md:flex-row md:flex pb-4 items-center justify-between mb-2 sticky bg-[#010320] top-[67px] lg:top-[78px]'>
                        <h4 className='mb-3 md:mb-0 text-base md:text-xl font-bold text-neutral-200'>Total - {games?.length}</h4>
                        {
                            totalPage > 1 &&
                                <div className='mb-3 md:mb-0'>
                                    <Pagination 
                                        setPage={setPage} 
                                        totalPage={totalPage}
                                        setLoading={setLoading} />
                                </div>
                        }
                        <div className={`${classes.form__group} ${classes.field}`}>
                            <input type="input" className={classes.form__field} placeholder="Search game" id='name' onChange={searchGameHandler}/>
                            <label htmlFor="name" className={classes.form__label}>Search game</label>
                        </div>
                    </div>
                    <div>
                        <ul className='flex items-center justify-center flex-wrap w-full'>
                            {
                                searchGames.length > 0 ?
                                filterGames.length > 0 &&
                                filterGames.map((game,i) =>
                                    <ProviderCard key={i} name={`${game.gameName.slice(0, 10)} ...`} image={game.imgFileName} imageLoad={imageLoad} setImageLoad={setImageLoad} event={() => launchGame(game.p_code,game.p_type,game.g_code,game.h5)}/>
                                )
                                :
                                games.length > 0 &&
                                games.map((game,i) => 
                                    <ProviderCard key={i} name={`${game.gameName.slice(0, 10)} ...`} image={game.imgFileName} imageLoad={imageLoad} setImageLoad={setImageLoad} event={() => launchGame(game.p_code,game.p_type,game.g_code,game.h5)} />
                                )
                            }
                        </ul>
                    </div>
                </div>
            </Layout>
        <Footer />
        </div>
        {
           ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
        }
    </>
  )
}

export default GameList
