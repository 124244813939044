import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Input from '../utils/Input';
import Select from '../utils/Select';
import Card from '../utils/Card';
import Button from '../utils/Button';
import {FiLock, FiTablet, FiUser } from 'react-icons/fi';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import logo from '../assets/images/logo/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import classes from '../assets/styles/Login.module.css'
import { useDispatch } from 'react-redux';
import config from '../config';
import {getMethod, postMethod} from '../services';
import {NAME_CHECK_API, REGISTER_API, REGISTER_OTP_API} from '../services/constant';
import SweetAlert from 'sweetalert2';
import {userDataHandler} from '../store/slices/registerSlice';
import Loader from '../utils/Loader';

const Register = () => {

  const [loading,setLoading] = useState(false);
  const [name,setName] = useState("");
  const [phone,setPhone] = useState("");
  const [password,setPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");

  const [errorName,setErrorName] = useState("");
  const [errorPhone,setErrorPhone] = useState("");
  const [errorPassword,setErrorPasword] = useState("");
  const [errorConfirmPassword,setErrorConfirmPassword] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();


  const nameHandler = (e) => {
    setName(e.target.value);
    setErrorName("");
};
const phoneHandler = e => {
   setPhone(e.target.value);
   setErrorPhone("");
};

const passwordHandler = e => {
   if(e.target.value.length > 8){
      return;
   }
   setPassword(e.target.value);
   setErrorPasword("");
};

const confirmPasswordHandler = e => {
  if(e.target.value.length > 8){
     return;
  };
  setConfirmPassword(e.target.value);
  setErrorConfirmPassword("");
};

const submitHandler = async e => {
    e.preventDefault();
    if(name){
        if(phone){
          if(phone.length >= 9 && phone.length <= 20){
            if(password){
              if(password.length === 8){
                if(confirmPassword){
                  setLoading(true);
                  const res = await postMethod(REGISTER_OTP_API,{phone,app_name_id: config.app_name_id});
                  console.log(res);
                  if(res){
                    setLoading(false);
                    if(res === 'Failed to fetch'){
                      SweetAlert.fire({
                        width: '330px',
                        icon: 'error',
                        title: 'Connection!',
                        text: 'Please check internet connection!',
                        confirmButtonText: 'Ok',
                      });
                    }
                    if(res?.con){
                      const data = {
                          name,phone,
                          password,
                          password_confirmation: confirmPassword,
                          otp: res.data,
                          merchant_id: config.merchant_id
                      };
                      const regsiterRes = await postMethod(REGISTER_API, data);
                      if(regsiterRes){
                        setLoading(false);
                        if(regsiterRes === 'Failed to fetch'){
                          SweetAlert.fire({
                            width: '330px',
                            icon: 'error',
                            title: 'Connection!',
                            text: 'Please check internet connection!',
                            confirmButtonText: 'Ok',
                          });
                        };
                        if(regsiterRes?.con){
                          SweetAlert.fire({
                            width: '330px',
                            icon: 'success',
                            title: 'Success!',
                            text: 'Register successful!',
                            confirmButtonText: 'Home',
                          }).then(result => {
                              if(result.isConfirmed){
                                  localStorage.setItem('l_k_auth', JSON.stringify(regsiterRes.data));
                                  navigate('/');
                              };
                          });
                        }
                      }
                    }else{
                      if(res?.data?.phone){
                        setErrorPhone(res.data.phone[0]);
                      };

                    };
                  }
             }else{
               setErrorConfirmPassword("Confirm password is required!")
             }
              }else{
                setErrorPasword("The password must be at least 8 characters!")
              };
            }else{
              setErrorPasword("Password is required!")
            }
          }else{
            setErrorPhone("Phone no is minium is 9 and maximum is 20");
          };
        }else{
           setErrorPhone("Phone is required!")
        };
    }else{
       setErrorName("Name is required!")
    }
};

useEffect(() => {
    if(password === confirmPassword){
       setErrorConfirmPassword("");
       setErrorPasword("");
    }else{
       setErrorConfirmPassword("Your password is does not match")
    }
},[confirmPassword,password]);


  return (
    <>
      <div className='mx-auto bg-[#010320] py-16'>
        <div>
            <div className='w-40 mb-6 mx-auto'>
                <Link to="/">
                    <img src={logo} className='w-full'/>
                </Link>
            </div>
        </div>
          <Card>
              <form onSubmit={submitHandler}>
                  <Input icon={<FiUser />} label="Name" type="text" value={name} event={nameHandler} error={errorName}/>
                  <Input icon={<FiTablet />} label="Phone" type="number" value={phone} event={phoneHandler} error={errorPhone}/>
                  <Input icon={<FiLock />} label="Password" type="password" value={password} event={passwordHandler} error={errorPassword}/>
                  <Input icon={<FiLock />} label="Confirm password" type="password" value={confirmPassword} event={confirmPasswordHandler} error={errorConfirmPassword}/>
                  <div className='text-center'>
                      <Button label="Confirm" type="submit" />
                  </div>
              </form>
          </Card>
          <div className={classes['forget-pass']}>
              <Link to="/login">
                  <p className={classes['forget-text']}>Already account? Login</p>
              </Link>
          </div>
      </div>
      {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default Register
