import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import Button from '../utils/Button';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../utils/Input';
import { useSelector } from 'react-redux';
import logo from '../assets/images/logo/logo.png'
import { postMethod } from '../services';
import { PASSWORD_RESET_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config';
import { FiLock } from 'react-icons/fi';
import Card from '../utils/Card';
import Loader from '../utils/Loader';

const PasswordCreate = () => {
    const [loading,setLoading] = useState(false);
    const [newPassword,setNewPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");

    const [errorNewPassword,setErrorNewPassword] = useState("");
    const [errorConfirmPassword,setErrorConfirmPassword] = useState("");

    const {registerVal} = useSelector(state => state.registerSlice);
    const navigate = useNavigate();
    useEffect(() => {
        if(!registerVal.phone){
        navigate('/forgot-password');
        };
    },[registerVal]);

    const newPasswordHandler = e => {
        if(e.target.value.length > 8 ){
           return;
        };
        setNewPassword(e.target.value);
        setErrorNewPassword("");
    };
   
    const confirmPasswordHandler = e => {
        if(e.target.value.length > 8 ){
           return;
        };
        setConfirmPassword(e.target.value);
        setErrorConfirmPassword("");
    };

    const submitHandler = async e => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...registerVal,
            password: newPassword,
            password_confirmation: confirmPassword,
            app_name_id: config.app_name_id
        };
        if(newPassword === confirmPassword){
           const res = await postMethod(PASSWORD_RESET_API, data);
            if(res){
             setLoading(false);
             if(res === 'Failed to fetch'){
               SweetAlert.fire({
                 width: '330px',
                 icon: 'error',
                 title: 'Connection!',
                 text: 'Please check internet connection!',
                 confirmButtonText: 'Ok',
               });
             };
             if(res?.con){
                 SweetAlert.fire({
                   width: '330px',
                   icon: 'success',
                   title: 'Success!',
                   text: 'Forgot password successful!',
                   confirmButtonText: 'Ok',
                 }).then(result => {
                     if(result.isConfirmed){
                         navigate('/login');
                     };
                 });
             }else{
                 if(res?.data?.error){
                   setErrorNewPassword(res.data.error);
                   navigate(-1);
                 }
             };
            }
        }else{
           setErrorNewPassword("Password and confirm password must match");
           setErrorConfirmPassword("Password and confirm password must match");
           setLoading(false);
        }
    };

    useEffect(() => {
        if(newPassword === confirmPassword){
           setErrorConfirmPassword("");
           setErrorNewPassword("");
        }else{
           setErrorConfirmPassword("Your password is does not match")
        }
    },[confirmPassword,newPassword]);

  return (
    <>
      <div className='mx-auto bg-[#010320] py-16 h-screen'>
            <div>
                <div className='w-40 mb-6 mx-auto'>
                    <Link to="/">
                        <img src={logo} className='w-full'/>
                    </Link>
                </div>
                <Card>
                    <form onSubmit={submitHandler}>
                        <Input icon={<FiLock />} label="Password" type="password" value={newPassword} event={newPasswordHandler} error={errorNewPassword}/>
                        <Input icon={<FiLock />} label="Confirm password" type="password" value={confirmPassword} event={confirmPasswordHandler} error={errorConfirmPassword}/>
                        <div className='text-center'>
                            <Button label="Confirm" type="submit" disable={loading}/>
                        </div>
                    </form>
                </Card>
            </div>
      </div>
      {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default PasswordCreate
