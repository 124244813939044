import React from 'react'
import { BrowserRouter as Router, Routes,Route} from "react-router-dom";
import Main from '../components/Main';
import GameList from '../components/GameList';
import Login from '../auths/Login';
import Register from '../auths/Register';
import ForgotPassword from '../auths/ForgotPassword';
import ForgotVerify from '../auths/ForgotVerify';
import PasswordCreate from '../auths/PasswordCreate';
import ChangePassword from '../auths/ChangePassword';
import RouteGuard from '../routes/RouteGuard';
import Deposit from '../components/Deposit';
import Withdraw from '../components/Withdraw';
const Routers = () => {
  return (
    <Router>
        <Routes>
           {/* ----------- auth ---------- */}
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify/password' element={<ForgotVerify />} />
            <Route path='/verify/password-create' element={<PasswordCreate />} />
            <Route path='/change-password' element={<RouteGuard><ChangePassword /></RouteGuard>} />
            {/* ----------- games ---------- */}
            <Route path="/:name/:id" element={<RouteGuard><GameList /></RouteGuard>} />

            {/* ----------- Payment ---------- */}
            <Route path="/deposit" element={<RouteGuard><Deposit /></RouteGuard>} />
            <Route path="/deposit-history" element={<RouteGuard><Deposit /></RouteGuard>} />
            <Route path="/withdraw" element={<RouteGuard><Withdraw /></RouteGuard>} />
            <Route path="/withdraw-history" element={<RouteGuard><Withdraw /></RouteGuard>} />

            <Route path='/' element={<Main />} />
        </Routes>
    </Router>
  )
}

export default Routers