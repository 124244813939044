import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Input from '../utils/Input';
import Select from '../utils/Select';
import Button from '../utils/Button';
import classes from '../assets/styles/WithdrawForm.module.css';
import SweetAlert from 'sweetalert2';
import {getMethod,postMethod} from '../services'
import {PAYMENT_PROVIDER_API,USER_INFO_API,USER_WITHDRAW_API} from '../services/constant';
import Loader from '../utils/Loader';
import {useNavigate} from 'react-router-dom';
import { AiFillDollarCircle} from 'react-icons/ai';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FiLock, FiTablet, FiUser } from 'react-icons/fi';
import config from '../config';

const WithdrawForm = () => {
//    ------ data -------
    const [loading,setLoading] = useState(false);
    const [userId,setUserId] = useState(null);
    const [payments,setPayments] = useState([]);
    const [amount,setAmount] = useState("");
    const [paymentType,setPaymentType] = useState("");
    const [paymentAccno,setPaymentAccno] = useState("");
    const [password,setPassword] = useState("");

//    ------ errors -------
    const [errorAmount,setErrorAmount] = useState("");
    const [errorPaymentType,setErrorPaymentType] = useState("");
    const [errorPaymentAccno,setErrorPaymentAccno] = useState("");
    const [errorPassword,setErrorPassword] = useState("");
    const navigate = useNavigate();

    const fetchUserInfo = async () => {
        const token = localStorage.getItem('l_k_auth');
        if(token){
            const deToken = JSON.parse(token);
            let res = await getMethod(USER_INFO_API, deToken.token);
            if(res){
                if(res.con){
                    setUserId(res?.data?.user_info?.id);
                };
            };
        };
      };
    const fetchPaymentProviders = async () => {
        const token = localStorage.getItem('l_k_auth');
        if(token){
           const deToken = JSON.parse(token);
           const res = await getMethod(`${PAYMENT_PROVIDER_API}?app_name_id=${config.app_name_id}`, deToken.token);
           if(res){
              setLoading(false);
              if(res === 'Failed to fetch'){
                  setLoading(false);
                  SweetAlert.fire({
                    width: '330px',
                    icon: 'error',
                    title: 'Connection!',
                    text: 'Please check internet connection!',
                    confirmButtonText: 'Ok',
                  });
              };
              if(res.con){
                  setPayments(res.data);
              };
           };
        };
    };

    useEffect(()=>{
        fetchPaymentProviders();
        fetchUserInfo();
    },[]);

   const withdrawSubmitHandler = async (e) => {
       e.preventDefault();
       if(amount){
           if(Number(amount) >= 2000){
                if(paymentType){
                     if(paymentAccno){
                        if(paymentAccno.length >= 9 && paymentAccno.length <= 20){
                            if(password.length > 0){
                                setLoading(true);
                                const data = {
                                    user_id: userId,
                                    amount,
                                    payment_provider_id: paymentType,
                                    phone: paymentAccno,
                                    password
                                };
                                const token = localStorage.getItem('l_k_auth');
                                if(token){
                                    const deToken = JSON.parse(token);
                                    const res = await postMethod(USER_WITHDRAW_API, data, deToken.token)
                                    if(res){
                                        setLoading(false);
                                        if(res === 'Failed to fetch'){
                                            SweetAlert.fire({
                                              width: '330px',
                                              icon: 'error',
                                              title: 'Connection!',
                                              text: 'Please check internet connection!',
                                              confirmButtonText: 'Ok',
                                            });
                                        };
                                        if(res.con){
                                            SweetAlert.fire({
                                                width: '330px',
                                                icon: 'success',
                                                title: 'Success!',
                                                text: 'Withdraw successful!',
                                                confirmButtonText: 'Ok',
                                            }).then(result => {
                                                if(result.isConfirmed){
                                                    navigate('/');
                                                }
                                            });
                                        }else{
                                            if(res.message !== null){
                                                if(res.message.password){
                                                    setErrorPassword(res.message.password[0])
                                                }
                                            };
                                            if(res.data.error){
                                                setErrorPassword(res.data.error);
                                            };
                                        }
                                    }
                                };
                            }else{
                                setErrorPassword("Password is required!")
                            }
                        }else{
                            setErrorPaymentAccno("Payment account no is minium is 9 and maximum is 20");
                        };
                     }else{
                        setErrorPaymentAccno("Payment account no is required!");
                     }
                }else{
                    setErrorPaymentType("Payment is required!");
                }
           }else{
              setErrorAmount("Minium withdraw amount is 2000");
           };
       }else{
         setErrorAmount("Amount is required!");
       }

   };

  return (
    <>
        <div className={classes['auth-card-body']}>
            <div>
                <form onSubmit={withdrawSubmitHandler}>
                    <Input icon={<AiFillDollarCircle/>} label="Amount" type="number" value={amount} placeholder="Withdraw amount" event={e => {
                        setErrorAmount("");
                        setAmount(e.target.value)
                    }} error={errorAmount}/>
                    <Select icon={<FaRegMoneyBillAlt/>} label="Payment type" event={e => {
                        setPaymentType(e.target.value);
                        setErrorPaymentType("")
                    }} error={errorPaymentType}>
                        <option value="#">Choose payment</option>
                    {
                        payments.length > 0 &&
                        payments.map((payment,i) => 
                            <option key={i} value={payment.id}>{payment.name}</option>
                        )
                    }
                    </Select>
                    <Input icon={<FiTablet />} label="Account number" type="number" value={paymentAccno} placeholder="Payment account no" event={e => {
                            setErrorPaymentAccno("");
                            setPaymentAccno(e.target.value)
                    }
                    } error={errorPaymentAccno}/>
                    <Input icon={<FiLock />} label="Password" type="password" value={password} placeholder="Payment account no" event={e => {
                            setErrorPassword("");
                            setPassword(e.target.value)
                    }
                    } error={errorPassword}/>
                    <div className={classes['btn-position']}>
                        <Button label="Confirm" type="submit" bgColor="#6b67eb" bgHover="#333258"/>
                    </div>
                </form>
            </div>
        </div>
        {
            ReactDOM.createPortal(loading && <Loader/>, document.getElementById('portal'))
        }
    </>
  )
}

export default WithdrawForm