import React from 'react'
import classes from '../assets/styles/Layout.module.css';
const Layout = ({smTitle,title,children}) => {
  return (
    <div className='container mx-auto px-4'>
       <div className={classes['section-title']} data-aos="fade-up">
          <h2>{smTitle}</h2>
          <p>{title}</p>
        </div>
        {children}
    </div>
  )
}

export default Layout
