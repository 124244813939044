import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/ForgotPassword.module.css';
import Button from '../utils/Button';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../utils/Input';
import logo from '../assets/images/logo/logo.png';
import { cleanUserDataHandler, userDataHandler } from '../store/slices/registerSlice';
import { useDispatch } from 'react-redux';
import {postMethod} from '../services';
import { PASSWORD_CREATE_OTP_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config';
import Card from '../utils/Card';
import { FiTablet } from 'react-icons/fi';

const ForgotPassword = () => {
  const [loading,setLoading] = useState(false);
  const [phone,setPhone] = useState("");
  const [errorPhone,setErrorPhone] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneHandler = e => {
     setPhone(e.target.value);
     setErrorPhone("");
  };
  const forgetSubmit = async e => {
     e.preventDefault();
     if(phone){
        if(phone.length >= 9 && phone.length <= 20){
            setLoading(true);
            const res = await postMethod(PASSWORD_CREATE_OTP_API, {phone,app_name_id: config.app_name_id});
            if(res){
              setLoading(false);
              if(res === 'Failed to fetch'){
                SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: 'Please check internet connection!',
                  confirmButtonText: 'Ok',
                });
              }
              if(res.con){
                dispatch(userDataHandler({phone}));
                navigate('/verify/password');
              }else{
                if(res?.data?.phone){
                  setErrorPhone(res.data.phone[0]);
                };
              };
            }
        }else{
          setErrorPhone("Phone is minium is 9 and maximum is 20");
        }
     }else{
        setErrorPhone("Phone is required!")
     };
  };

  useEffect(()=>{
    dispatch(cleanUserDataHandler());
  },[])
  return (
    <div className='mx-auto bg-[#010320] h-screen pt-16'>
        <div>
            <div className='w-40 mb-6 mx-auto'>
                <Link to="/">
                    <img src={logo} className='w-full'/>
                </Link>
            </div>
        </div>
        <Card>
            <form onSubmit={forgetSubmit}>
                <Input icon={<FiTablet />} label="Register phone" type="number" value={phone} event={phoneHandler} error={errorPhone}/>
                <div className='text-center'>
                    <Button label="Confirm" type="submit" />
                </div>
            </form>
        </Card>
        <div className={classes['forget-pass']}>
            <Link to="/login">
                <p className={classes['forget-text']}>Already account? Login</p>
            </Link>
        </div>
    </div>
  )
}

export default ForgotPassword