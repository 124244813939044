import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from '../assets/styles/DepositHistory.module.css';
import {getMethod} from '../services';
import {WITHDRAW_HISTORY_API} from '../services/constant';
import Loader from '../utils/Loader';
import SweetAlert from 'sweetalert2';
import NoData from '../utils/NoData';
import config from '../config'

const WithdrawHistory = () => {
  const [loading,setLoading] = useState(true);
  const [history,setHistory] = useState([]);

  const fetchHistory = async () => {
      const token = localStorage.getItem('l_k_auth');
      if(token){
          const deToken = JSON.parse(token);
          const res = await getMethod(`${WITHDRAW_HISTORY_API}?user_id=${deToken.user_id}&app_name_id=${config.app_name_id}`,deToken.token);
          if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
                SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: 'Please check internet connection!',
                  confirmButtonText: 'Ok',
                });
            };
            if(res.con){
                setHistory(res.data);
            };
            
          };
      };
  }

  useEffect(()=>{
    fetchHistory();
  },[]);

  return (
    <>
        <div className={classes['auth-card-body']}>
            <div className={classes['table-card-container']}>
                <ul className={classes['table-header']}>
                    <li><b>No.</b></li>
                    <li>Payment</li>
                    <li>Amount</li>
                    <li>Status</li>
                    <li>Date</li>
                    <li>Remark</li>
                </ul>
                {
                    history.length > 0 ?
                    history.map((hist,i) =>
                    <ul className={classes['table-header']} key={i}>
                        <li>{i + 1}.</li>
                        <li>{hist.payment_provider_name}</li>
                        <li style={{textAlign: 'right'}}>{hist.amount?.toLocaleString('en-us')}</li>
                        <li style={{color: hist.status === "Rejected" ? 'red':'green'}}>{hist.status}</li>
                        <li>{hist.created_at}</li>
                        <li>{hist.remark??"--"}</li>
                    </ul>
                    )
                    :
                    <NoData />

                }
            </div>
        </div>
        {
           ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
        }
    </>
  )
}
export default WithdrawHistory