import React from 'react';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({children}) => {
  const token = localStorage.getItem('l_k_auth');
  if(token){
     return children;
  }else{
     return <Navigate to="/login" />
  }
}

export default RouteGuard