import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo/logo.png';
import classes from '../assets/styles/Header.module.css';
import {FiMenu, FiX} from 'react-icons/fi';
import {motion} from 'framer-motion';
import profileImage from '../assets/images/icons/profile.png';
import {USER_INFO_API} from '../services/constant';
import {getMethod} from '../services';

const Header = () => {
  const [data,setData] = useState({});
  const [menu,setMenu] = useState(false);
  const navigate = useNavigate();
  const toggleMenuHandler = () => {
      setMenu(!menu);
  };

  const fetchUserInfo = async () => {
    const token = localStorage.getItem('l_k_auth');
    if(token){
        const deToken = JSON.parse(token);
        let res = await getMethod(USER_INFO_API, deToken.token);
        if(res){
           if(res.message === "Unauthenticated."){
                navigate('/login');
           };
           if(res.con){
              setData(res.data?.user_info);
           };
        };
    };
};

useEffect(()=>{
  fetchUserInfo();
},[]);

const logoutHandler = () => {
    const token = localStorage.getItem('l_k_auth');
    if(token){
       localStorage.removeItem('l_k_auth');
       navigate('/');
    }else{
       navigate('/');
    };
};
  return (
    <div className={`bg-[#1e2b3e] sticky top-0 z-50`}>
        <nav className="container mx-auto bg-transparent flex justify-between items-center px-4">
            <div className='flex items-center'>
                <Link to="/">
                    <img src={logo} alt='logo' className='w-24 p-3 lg:w-28'/>
                </Link>
                <div className='hidden lg:block ml-20'>
                    <ul className='flex justify-between items-center select-none'>
                        <li className={`relative ${classes['sidebar-icon']}`}>
                            <Link to="/">
                                <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Home</span>
                            </Link>
                        </li>
                        { 
                            localStorage.getItem('l_k_auth') &&
                            <>
                                <li className={`relative ${classes['sidebar-icon']}`}>
                                    <Link to="/deposit">
                                        <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Deposit</span>
                                    </Link>
                                </li>
                                <li className={`relative ${classes['sidebar-icon']}`}>
                                    <Link to="/withdraw">
                                        <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Withdraw</span>
                                    </Link>
                                </li>
                                <li className={`relative ${classes['sidebar-icon']}`}>
                                    <Link to="/change-password">
                                        <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Change password</span>
                                    </Link>
                                </li>
                                <li className={`relative ${classes['sidebar-icon']}`} onClick={logoutHandler}>
                                    <Link to="#">
                                        <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Logout</span>
                                    </Link>
                                </li>
                            </>
                        }
                    </ul>
                </div>
            </div>
            <div className='lg:hidden relative'>
                <FiMenu className='text-3xl' onClick={toggleMenuHandler}/>
                <motion.div 
                initial={{ opacity: 0, display: 'none'}}
                animate={{ opacity: menu?1:0, display: menu? 'block': 'none'}}
                transition={{ duration: 0.3 }}
                className={`${classes['dropDown-backDrop']} py-12 px-3`}>
                    <FiX className='text-4xl absolute right-3 top-3' onClick={toggleMenuHandler}/>
                    <div className='h-full bg-white rounded-xl p-5'>
                        {
                            localStorage.getItem('l_k_auth') &&
                                <div className={`${classes['profile']} mb-4`}>
                                        <img src={profileImage} className={classes['profile-img']} />
                                        <div>
                                            <p className={`${classes['user-name']} text-[#354ebded]`}>{data?.name ?? "--"}</p>
                                            <p className={classes['user-balance']}>{data?.balance?.toLocaleString('en-us') ?? 0}</p>
                                        </div>
                                </div>
                        }
                        <ul>
                            <li className={`relative ${classes['sidebar-icon-mobile']}`}>
                                <Link to="/">
                                    <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Home</span>
                                </Link>
                            </li>
                            { 
                                localStorage.getItem('l_k_auth') ?
                                <>
                                    <li className={`relative ${classes['sidebar-icon-mobile']}`}>
                                        <Link to="/deposit">
                                            <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Deposit</span>
                                        </Link>
                                    </li>
                                    <li className={`relative ${classes['sidebar-icon-mobile']}`}>
                                        <Link to="/withdraw">
                                            <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Withdraw</span>
                                        </Link>
                                    </li>
                                    <li className={`relative ${classes['sidebar-icon-mobile']}`}>
                                        <Link to="/change-password">
                                            <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Change passoword</span>
                                        </Link>
                                    </li>
                                    <li className={`relative ${classes['sidebar-icon-mobile']}`} onClick={logoutHandler}>
                                        <Link to="#">
                                            <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Logout</span>
                                        </Link>
                                    </li>
                                </>
                                :
                                <>
                                    <li className={`relative ${classes['sidebar-icon-mobile']}`}>
                                        <Link to="/login">
                                            <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Login</span>
                                        </Link>
                                    </li>
                                    <li className={`relative ${classes['sidebar-icon-mobile']}`}>
                                        <Link to="/register">
                                            <span className={`py-2 block font-bold ${classes['hover-underline-mobile']}`}>Register</span>
                                        </Link>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </motion.div>
            </div>
            {
                localStorage.getItem('l_k_auth') ?
                <div className='hidden lg:block'>
                    <div className={`${classes['profile']}`}>
                            <img src={profileImage} className={classes['profile-img']} />
                            <div>
                                <p className={classes['user-name']}>{data?.name ?? "--"}</p>
                                <p className={classes['user-balance']}>{data?.balance?.toLocaleString('en-us') ?? 0}</p>
                            </div>
                    </div>
                 </div>:
                 <ul className='hidden lg:flex justify-between items-center select-none'>
                        <li className={`relative ${classes['sidebar-icon']}`}>
                            <Link to="/login">
                                <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Login</span>
                            </Link>
                        </li>
                        <li className={`relative ${classes['sidebar-icon']}`}>
                            <Link to="/register">
                                <span className={`px-3.5 py-2 block font-bold ${classes['hover-underline']}`}>Register</span>
                            </Link>
                        </li>
                 </ul>
            }
        </nav>
    </div>
  )
}

export default Header
