import React, { useState } from 'react'
import classes from '../assets/styles/Input.module.css';

const Input = ({icon,label,children,event,error}) => {
  const [toggleIcon, setToggleIcon] = useState(false);
    return (
    <div className={classes['input-group']}>
        <label htmlFor={label} className={classes['input-label']}>{label}</label>
        <div className={classes['input-container']}>
            <span className={classes['input-icon']}>
                {icon}
            </span>
            <select className={classes['input-box']} style={{paddingRight: '8px'}} onChange={event}>
                {
                  children
                }
            </select>
        </div>
        {
            error &&
            <span className={classes['error-message']}>{error}</span>
        }
    </div>
    )
}

export default Input