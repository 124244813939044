import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imageLoader from '../assets/images/preload.png';
const ProviderCard = ({name,image,imageLoad,setImageLoad,event}) => {
  return (
    <li className='w-1/3 md:w-1/4 lg:w-1/6 cursor-pointer select-none p-2' onClick={event}>
        <LazyLoadImage
            className="object-cover w-full h-full"
            alt=""
            // effect="blur"
            src={imageLoad ? imageLoader:image}
            afterLoad={() => setImageLoad(false)}
            beforeLoad={() => setImageLoad(true)}/>
        <p className='mb-0 mt-1.5 select-none text-center text-neutral-200 text-sm lg:text-base font-extrabold'>{name}</p>
    </li>
  )
}

export default ProviderCard
