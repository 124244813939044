import React from 'react'
import Header from '../layout/Header'
import Banner from './Banner'
import ProviderList from './ProviderList'
import Contact from './Contact'
import Footer from '../layout/Footer'

const Main = () => {
  return (
    <div>
        <Header />
        <Banner />
        <ProviderList />
        <Contact />
        <Footer />
    </div>
  )
}

export default Main
