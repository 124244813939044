import React from 'react'
import classes from '../assets/styles/RadioInput.module.css';
const RadioInput = ({id,value,amountEvent}) => {
  return (
    <div className={classes['amount-group']}>
        <input type='radio' id={id} value={value} name='payment-amount' className={`${classes['radio-custom']} isChecked`} onChange={amountEvent}/>
        <label htmlFor={id} className={classes['amount']}>
            <span>{value}</span>
        </label>
    </div>
  )
}

export default RadioInput